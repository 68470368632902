define("discourse/plugins/discourse-layouts/discourse/layouts-admin-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    resource: "admin",
    map() {
      this.route("adminLayouts", {
        path: "/layouts",
        resetNamespace: true
      }, function () {
        this.route("widgets", {
          path: "/widgets"
        });
      });
    }
  };
});