define("discourse/plugins/discourse-layouts/discourse/widgets/login-required", ["exports", "discourse/widgets/widget"], function (_exports, _widget) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _widget.createWidget)("login-required", {
    tagName: "div.widget-login-required",
    html() {
      return this.attach("button", {
        label: "log_in",
        className: "btn-primary btn-small login-button",
        action: "showLogin",
        icon: "user"
      });
    }
  });
});