define("discourse/plugins/discourse-layouts/discourse/routes/admin-layouts", ["exports", "discourse/routes/discourse"], function (_exports, _discourse) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    redirect() {
      this.transitionTo("adminLayouts.widgets");
    },
    actions: {
      showSettings() {
        const controller = this.controllerFor("adminSiteSettings");
        this.transitionTo("adminSiteSettingsCategory", "plugins").then(() => {
          controller.set("filter", "layouts");
          controller.set("_skipBounce", true);
          controller.filterContentNow("plugins");
        });
      }
    }
  });
});