define("discourse/plugins/discourse-layouts/discourse/components/sidebar-container", ["exports", "discourse/components/mount-widget", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/application", "@ember/runloop", "discourse/plugins/discourse-layouts/discourse/lib/layouts", "@ember/service"], function (_exports, _mountWidget, _decorators, _computed, _application, _runloop, _layouts, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _mountWidget.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("context"), _dec3 = (0, _decorators.observes)("path", "mobileView", "tabletView"), (_obj = {
    classNameBindings: [":layouts-sidebar-container", "editing"],
    widget: "sidebar",
    router: (0, _service.inject)(),
    path: (0, _computed.alias)("router._router.currentPath"),
    setupRerenderTrigger() {
      this.appEvents.on("sidebars:rerender", () => {
        this.rerenderSidebars();
      });
    },
    controller(context) {
      return (0, _application.getOwner)(this).lookup(`controller:${(0, _layouts.getAttrFromContext)(context, "controller")}`);
    },
    buildArgs() {
      const side = this.side;
      const controller = this.controller;
      const context = this.context;
      const path = this.path;
      const tabletView = this.tabletView;
      const mobileView = this.mobileView;
      const sidebarMinimized = this.sidebarMinimized;
      let args = {
        context,
        path,
        side,
        controller,
        mobileView,
        tabletView,
        sidebarMinimized
      };
      if (context === "discovery/categories") {
        args.filter = this.filter;
      }
      if (["discovery", "topic"].indexOf(context) > -1 && path !== "discovery.categories") {
        args.category = this.category;
      }
      if (context === "topic") {
        args.topic = this.topic;
      }
      const customSidebarProps = this.customSidebarProps;
      if (customSidebarProps) {
        args.customSidebarProps = customSidebarProps;
      }
      return args;
    },
    rerenderSidebars() {
      this.queueRerender();
      (0, _runloop.scheduleOnce)("afterRender", () => {
        this.appEvents.trigger("sidebars:after-render");
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "setupRerenderTrigger", [_dec], Object.getOwnPropertyDescriptor(_obj, "setupRerenderTrigger"), _obj), _applyDecoratedDescriptor(_obj, "controller", [_dec2], Object.getOwnPropertyDescriptor(_obj, "controller"), _obj), _applyDecoratedDescriptor(_obj, "rerenderSidebars", [_dec3], Object.getOwnPropertyDescriptor(_obj, "rerenderSidebars"), _obj)), _obj)));
});