define("discourse/plugins/discourse-layouts/discourse/controllers/admin-layouts-widgets", ["exports", "@ember/object", "@ember/controller"], function (_exports, _object, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _controller.default.extend({
    actions: {
      addWidget() {
        this.get("widgets").unshiftObject(_object.default.create({
          isNew: true
        }));
      },
      removeWidget(widget) {
        this.get("widgets").removeObject(widget);
      }
    }
  });
});