define("discourse/plugins/discourse-layouts/discourse/mixins/sidebars", ["exports", "@ember/object", "@ember/object/computed", "@ember/object/mixin", "@ember/runloop", "@ember/service", "@ember/template", "discourse-common/lib/icon-library", "discourse-common/utils/decorators", "discourse/lib/url", "discourse/plugins/discourse-layouts/discourse/lib/layouts"], function (_exports, _object, _computed, _mixin, _runloop, _service, _template, _iconLibrary, _decorators, _url, _layouts) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function hasWidgets(widgets, widgetsSet) {
    return !widgetsSet || widgets && widgets.length > 0;
  }
  var _default = _exports.default = _mixin.default.create((_dec = (0, _decorators.default)("layouts_context", "mobileView"), _dec2 = (0, _decorators.default)("layouts_context", "mobileView"), _dec3 = (0, _decorators.default)("rightSidebarVisible"), _dec4 = (0, _decorators.default)("leftSidebarVisible"), _dec5 = (0, _decorators.on)("init"), _dec6 = (0, _decorators.observes)("leftSidebarEnabled", "mobileView", "tabletView", "leftSidebarMinimized", "leftSidebarVisible"), _dec7 = (0, _decorators.on)("willDestroy"), _dec8 = (0, _decorators.default)("path", "loading", "mobileView", "tabletView", "hasRightSidebar", "hasLeftSidebar", "showMobileMenu"), _dec9 = (0, _decorators.default)("mobileView", "tabletView", "leftSidebarVisible", "leftSidebarMinimized"), _dec10 = (0, _decorators.default)("mobileView", "tabletView", "rightSidebarVisible"), _dec11 = (0, _decorators.default)("path", "hasLeftSidebar", "hasRightSidebar"), _dec12 = (0, _decorators.default)("hasLeftSidebar", "hasRightSidebar"), _dec13 = (0, _decorators.default)("path", "mobileView", "leftSidebarVisible", "leftSidebarMinimized"), _dec14 = (0, _decorators.default)("path", "mobileView", "rightSidebarVisible"), _dec15 = (0, _decorators.default)("leftSidebarEnabled", "rightSidebarEnabled"), (_obj = {
    router: (0, _service.inject)(),
    siteSettings: (0, _service.inject)(),
    appEvents: (0, _service.inject)(),
    path: (0, _computed.alias)("router._router.currentPath"),
    mobileView: false,
    tabletView: false,
    showMobileMenu: (0, _computed.and)("mobileView", "mobileMenuItems.length"),
    showLeftToggle: (0, _computed.and)("showSidebarToggles", "leftSidebarEnabled"),
    showRightToggle: (0, _computed.and)("showSidebarToggles", "rightSidebarEnabled"),
    customSidebarProps: {},
    eitherSidebarVisible: (0, _computed.or)("leftSidebarVisible", "rightSidebarVisible"),
    neitherSidebarVisible: (0, _computed.not)("eitherSidebarVisible"),
    leftSidebarEnabled: (0, _object.computed)("leftWidgets", "widgetsSet", function () {
      return hasWidgets(this.leftWidgets, this.widgetsSet);
    }),
    rightSidebarEnabled: (0, _object.computed)("rightWidgets", "leftFull", "widgetsSet", function () {
      return !this.leftFull && hasWidgets(this.rightWidgets, this.widgetsSet);
    }),
    hasRightSidebar: (0, _computed.and)("rightSidebarEnabled", "rightSidebarVisible"),
    hasLeftSidebar: (0, _computed.and)("leftSidebarEnabled", "leftSidebarVisible"),
    widgetsSet: (0, _computed.or)("leftWidgetsSet", "rightWidgetsSet"),
    leftFull: (0, _computed.equal)("siteSettings.layouts_sidebar_left_position", "full"),
    canHideRightSidebar(context, mobileView) {
      return this.canHide(context, "right", mobileView);
    },
    canHideLeftSidebar(context, mobileView) {
      return this.canHide(context, "left", mobileView);
    },
    canHide(context, side, mobileView) {
      return !mobileView && this.siteSettings[`layouts_sidebar_${side}_can_hide`].split("|").map(_layouts.normalizeContext).includes((0, _layouts.normalizeContext)(context));
    },
    toggleRightSidebarIcon(visible) {
      const settings = this.siteSettings;
      return visible ? settings.layouts_sidebar_hide_icon : settings.layouts_sidebar_show_icon;
    },
    toggleLeftSidebarIcon(visible) {
      const settings = this.siteSettings;
      return visible ? settings.layouts_sidebar_hide_icon : settings.layouts_sidebar_show_icon;
    },
    setupMixin() {
      const settings = this.siteSettings;
      const sidebarPadding = 20;
      const mainLeftOffset = settings.layouts_sidebar_left_width + sidebarPadding;
      const mainRightOffset = settings.layouts_sidebar_right_width + sidebarPadding;
      (0, _runloop.scheduleOnce)("afterRender", () => {
        this.handleWindowResize();
        $(window).on("resize", () => (0, _runloop.debounce)(this, this.handleWindowResize, 100));
        const root = document.documentElement;
        root.style.setProperty("--mainLeftOffset", `${this.mainLeftOffset}px`);
        root.style.setProperty("--mainRightOffset", `${this.mainRightOffset}px`);
      });
      this.appEvents.on("sidebar:toggle", this, this.toggleSidebars);
      let leftSidebarVisible = this.sidebarVisibleDefault("left");
      let rightSidebarVisible = this.sidebarVisibleDefault("right");
      let cachedLeftSidebar = localStorage.getItem("layouts-left-sidebar-minimized");
      let leftSidebarMinimized = ["true", "false"].includes(cachedLeftSidebar) ? cachedLeftSidebar === "true" : settings.layouts_sidebar_left_default_minimized;
      this.setProperties({
        mainLeftOffset,
        mainRightOffset,
        leftSidebarVisible,
        rightSidebarVisible,
        leftWidgetsSet: false,
        rightWidgetsSet: false,
        leftSidebarMinimized,
        rightSidebarMinimized: false
      });
      this.router.on("routeWillChange", transition => {
        // won't run on initial load
        if (transition.from) {
          this.setProperties({
            leftWidgetsSet: false,
            rightWidgetsSet: false
          });
        }
      });
    },
    toggleBodyClasses() {
      const leftSidebarEnabled = this.get("leftSidebarEnabled");
      const leftSidebarVisible = this.get("leftSidebarVisible");
      const mobileView = this.get("mobileView");
      const tabletView = this.get("tabletView");
      const leftFull = this.get("leftFull");
      const leftSidebarMinimized = this.get("leftSidebarMinimized");
      let addClasses = [];
      let removeClasses = [];
      if (!mobileView && leftSidebarEnabled && leftSidebarVisible && leftFull) {
        addClasses.push(`${_layouts.layoutsNamespace}-left-full`);
      } else {
        removeClasses.push(`${_layouts.layoutsNamespace}-left-full`);
      }
      if (!mobileView && leftSidebarMinimized) {
        addClasses.push(`${_layouts.layoutsNamespace}-sidebar-minimized`);
      } else {
        removeClasses.push(`${_layouts.layoutsNamespace}-sidebar-minimized`);
      }
      if (tabletView) {
        addClasses.push(`${_layouts.layoutsNamespace}-tablet`);
      } else {
        removeClasses.push(`${_layouts.layoutsNamespace}-tablet`);
      }
      addClasses = addClasses.filter(className => !removeClasses.includes(className));
      if (addClasses.length) {
        document.body.classList.add(...addClasses);
      }
      if (removeClasses.length) {
        document.body.classList.remove(...removeClasses);
      }
    },
    teardownMixin() {
      $(window).off("resize", (0, _runloop.bind)(this, this.handleWindowResize));
      this.appEvents.off("sidebar:toggle", this, this.toggleSidebars);
    },
    sidebarVisibleDefault(side) {
      if (this.mobileView) {
        return false;
      }
      return this.siteSettings[`layouts_sidebar_${side}_default_visibility`] === "show";
    },
    toggleSidebars(opts) {
      const mobileView = this.mobileView;
      const {
        side,
        value,
        target
      } = opts;
      let type = opts.type || "visibility";
      if (target === "mobile" && !mobileView || target === "desktop" && mobileView) {
        return;
      }
      let sides = side ? [side] : ["left", "right"];
      sides.forEach(s => {
        if (type === "minimize") {
          localStorage.setItem("layouts-left-sidebar-minimized", value);
          this.set(`${s}SidebarMinimized`, value);
        } else {
          let newVal = [true, false].includes(value) ? value : !Boolean(this[`${s}SidebarVisible`]);
          if (mobileView) {
            const $sidebar = $(`.sidebar.${s}`);
            const $sidebarCloak = $(".sidebar-cloak");
            if (newVal) {
              $sidebar.addClass("open");
              $sidebarCloak.css("opacity", 0.5);
              $sidebarCloak.show();
            } else {
              $sidebar.removeClass("open");
              $sidebarCloak.css("opacity", 0);
              $sidebarCloak.hide();
            }
          }
          this.set(`${s}SidebarVisible`, newVal);
        }
      });
    },
    handleWindowResize() {
      const windowWidth = $(window).width();
      const mobileThreshold = this.siteSettings.layouts_sidebar_mobile_threshold;
      const tabletThreshold = this.siteSettings.layouts_sidebar_tablet_threshold;
      const mobileView = this.get("mobileView");
      const tabletView = this.get("tabletView");
      if (windowWidth < Number(mobileThreshold)) {
        if (!mobileView) {
          this.setProperties({
            mobileView: true,
            tabletView: false,
            leftSidebarVisible: false,
            rightSidebarVisible: false
          });
        }
      } else {
        if (mobileView) {
          this.setProperties({
            mobileView: false,
            leftSidebarVisible: true,
            rightSidebarVisible: true
          });
        }
        if (windowWidth < Number(tabletThreshold)) {
          if (!tabletView) {
            this.setProperties({
              tabletView: true,
              leftSidebarMinimized: true
            });
          }
        } else {
          if (tabletView) {
            this.setProperties({
              tabletView: false,
              leftSidebarMinimized: false
            });
          }
        }
      }
    },
    mainClasses(path, loading, mobileView, tabletView, hasRight, hasLeft, showMenu) {
      let p = path.split(".");
      let classes = `${p[0]} ${p[1] ? p[1].split(/(?=[A-Z])/)[0] : ""}`;
      if (hasLeft || hasRight) {
        classes += " has-sidebars";
      } else {
        classes += " no-sidebars";
      }
      if (hasLeft) {
        classes += " left-sidebar";
      }
      if (hasRight) {
        classes += " right-sidebar";
      }
      if (mobileView) {
        classes += " mobile";
        if (showMenu) {
          classes += " has-menu";
        }
      }
      if (tabletView) {
        classes += " tablet";
      }
      if (loading) {
        classes += " loading";
      }
      return classes;
    },
    leftClasses(mobileView, tabletView, visible) {
      return this.buildSidebarClasses(mobileView, tabletView, visible, "left");
    },
    rightClasses(mobileView, tabletView, visible) {
      return this.buildSidebarClasses(mobileView, tabletView, visible, "right");
    },
    buildSidebarClasses(mobileView, tabletView, visible, side) {
      let classes = "";
      if (mobileView) {
        classes += "mobile";
        if (visible) {
          classes += " open";
        }
      } else {
        if (!visible) {
          classes += " not-visible";
        }
        if (tabletView) {
          classes += " tablet";
        }
      }
      classes += ` ${this.siteSettings[`layouts_sidebar_${side}_position`]}`;
      return classes;
    },
    mainStyle(path, hasLeftSidebar, hasRightSidebar) {
      if (this.mobileView) {
        return;
      }
      const mainLeftOffset = this.mainLeftOffset;
      const mainRightOffset = this.mainRightOffset;
      const leftFull = this.leftFull;
      let offset = 0;
      let style = "";
      if (hasLeftSidebar) {
        offset += mainLeftOffset;
      }
      if (hasRightSidebar) {
        offset += mainRightOffset;
      }
      if (hasLeftSidebar && leftFull) {
        offset = 0;
      }
      style += `width: ${offset > 0 ? `calc(100% - ${offset}px)` : "100%"}`;
      return (0, _template.htmlSafe)(style);
    },
    rootStyle(hasLeftSidebar) {
      const root = document.documentElement;
      const leftFull = this.leftFull;
      if (hasLeftSidebar && leftFull) {
        root.style.setProperty("overflow-x", "hidden");
      }
    },
    leftStyle(path, mobileView, visible, leftSidebarMinimized) {
      const width = this.siteSettings.layouts_sidebar_left_width;
      let string;
      if (mobileView) {
        string = `width: 100vw; transform: translateX(${visible ? "0" : `-100vw`});`;
      } else {
        string = `width: ${visible ? width : 0}px;`;
      }
      if (!mobileView && leftSidebarMinimized) {
        string = "width: max-content";
      }
      return (0, _template.htmlSafe)(string);
    },
    rightStyle(path, mobileView, visible) {
      const width = this.siteSettings.layouts_sidebar_right_width;
      let string;
      if (mobileView) {
        string = `width: 100vw; transform: translateX(${visible ? `0` : `100vw`});`;
      } else {
        string = `width: ${visible ? width : 0}px;`;
      }
      return (0, _template.htmlSafe)(string);
    },
    mobileMenuItems() {
      const inputs = this.siteSettings.layouts_mobile_menu.split("|");
      return inputs.reduce((items, input) => {
        let firstSeperator = input.indexOf("~~");
        let lastSeperator = input.lastIndexOf("~~");
        let type = input.substring(0, firstSeperator),
          icon,
          url;
        let isLink = type === "link";
        let isSidebarToggle = ["left", "right"].indexOf(type) > -1;
        if (isLink) {
          icon = input.substring(firstSeperator + 2, lastSeperator);
          url = input.substring(lastSeperator + 2, input.length);
        } else if (isSidebarToggle) {
          icon = input.substring(firstSeperator + 2, input.length);
        }
        if (icon) {
          let iconClass, iconHtml, action, actionParam;
          if (isSidebarToggle && this[`${type}SidebarEnabled`]) {
            iconClass = `mobile-toggle ${type}`;
            action = "toggleSidebar";
            actionParam = type;
          } else if (isLink) {
            iconClass = "mobile-link";
            action = "goToLink";
            actionParam = url;
          }
          try {
            let iconUrl = new URL(icon);
            iconHtml = (0, _template.htmlSafe)(`<img src=${iconUrl.href} class="image-icon">`);
          } catch (_) {
            iconHtml = (0, _template.htmlSafe)((0, _iconLibrary.iconHTML)(icon));
          }
          if (iconHtml && iconClass && action && actionParam) {
            items.push({
              icon: iconHtml,
              class: iconClass,
              action,
              actionParam
            });
          }
        }
        return items;
      }, []);
    },
    actions: {
      toggleSidebar(side) {
        this.appEvents.trigger("sidebar:toggle", {
          side
        });
      },
      setWidgets(side, widgets) {
        this.set(`${side}Widgets`, widgets);
        this.set(`${side}WidgetsSet`, true);
      },
      goToLink(link) {
        _url.default.routeTo(link);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "canHideRightSidebar", [_dec], Object.getOwnPropertyDescriptor(_obj, "canHideRightSidebar"), _obj), _applyDecoratedDescriptor(_obj, "canHideLeftSidebar", [_dec2], Object.getOwnPropertyDescriptor(_obj, "canHideLeftSidebar"), _obj), _applyDecoratedDescriptor(_obj, "toggleRightSidebarIcon", [_dec3], Object.getOwnPropertyDescriptor(_obj, "toggleRightSidebarIcon"), _obj), _applyDecoratedDescriptor(_obj, "toggleLeftSidebarIcon", [_dec4], Object.getOwnPropertyDescriptor(_obj, "toggleLeftSidebarIcon"), _obj), _applyDecoratedDescriptor(_obj, "setupMixin", [_dec5], Object.getOwnPropertyDescriptor(_obj, "setupMixin"), _obj), _applyDecoratedDescriptor(_obj, "toggleBodyClasses", [_dec6], Object.getOwnPropertyDescriptor(_obj, "toggleBodyClasses"), _obj), _applyDecoratedDescriptor(_obj, "teardownMixin", [_dec7], Object.getOwnPropertyDescriptor(_obj, "teardownMixin"), _obj), _applyDecoratedDescriptor(_obj, "mainClasses", [_dec8], Object.getOwnPropertyDescriptor(_obj, "mainClasses"), _obj), _applyDecoratedDescriptor(_obj, "leftClasses", [_dec9], Object.getOwnPropertyDescriptor(_obj, "leftClasses"), _obj), _applyDecoratedDescriptor(_obj, "rightClasses", [_dec10], Object.getOwnPropertyDescriptor(_obj, "rightClasses"), _obj), _applyDecoratedDescriptor(_obj, "mainStyle", [_dec11], Object.getOwnPropertyDescriptor(_obj, "mainStyle"), _obj), _applyDecoratedDescriptor(_obj, "rootStyle", [_dec12], Object.getOwnPropertyDescriptor(_obj, "rootStyle"), _obj), _applyDecoratedDescriptor(_obj, "leftStyle", [_dec13], Object.getOwnPropertyDescriptor(_obj, "leftStyle"), _obj), _applyDecoratedDescriptor(_obj, "rightStyle", [_dec14], Object.getOwnPropertyDescriptor(_obj, "rightStyle"), _obj), _applyDecoratedDescriptor(_obj, "mobileMenuItems", [_dec15], Object.getOwnPropertyDescriptor(_obj, "mobileMenuItems"), _obj)), _obj)));
});