define("discourse/plugins/discourse-layouts/discourse/routes/admin-layouts-widgets", ["exports", "discourse/plugins/discourse-layouts/discourse/models/layout-widget", "discourse/routes/discourse", "@ember/object", "@ember/array"], function (_exports, _layoutWidget, _discourse, _object, _array) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return _layoutWidget.default.list();
    },
    setupController(controller, model) {
      controller.set("widgets", (0, _array.A)(model.map(w => _object.default.create(w))));
    }
  });
});