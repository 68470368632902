define("discourse/plugins/discourse-layouts/discourse/templates/admin/layouts", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    {{#admin-nav}}
    {{nav-item route='adminLayouts.widgets' label='admin.layouts.widgets.nav'}}
    <li><DButton @action={{route-action "showSettings"}} @data-auto-route={{true}} @label="admin.layouts.settings.label" /></li>
  {{/admin-nav}}
  
  <div class="admin-container">
    {{outlet}}
  </div>
  
  */
  {
    "id": "VmSvDNPB",
    "block": "[[[6,[39,0],null,null,[[\"default\"],[[[[1,\"  \"],[1,[28,[35,1],null,[[\"route\",\"label\"],[\"adminLayouts.widgets\",\"admin.layouts.widgets.nav\"]]]],[1,\"\\n  \"],[10,\"li\"],[12],[8,[39,2],null,[[\"@action\",\"@data-auto-route\",\"@label\"],[[28,[37,3],[\"showSettings\"],null],true,\"admin.layouts.settings.label\"]],null],[13],[1,\"\\n\"]],[]]]]],[1,\"\\n\"],[10,0],[14,0,\"admin-container\"],[12],[1,\"\\n  \"],[46,[28,[37,5],null,null],null,null,null],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"admin-nav\",\"nav-item\",\"d-button\",\"route-action\",\"component\",\"-outlet\"]]",
    "moduleName": "discourse/plugins/discourse-layouts/discourse/templates/admin/layouts.hbs",
    "isStrictMode": false
  });
});