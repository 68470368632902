define("discourse/plugins/discourse-layouts/discourse/lib/layouts", ["exports", "discourse/widgets/widget", "discourse/plugins/discourse-layouts/discourse/mixins/sidebars", "discourse/lib/plugin-api", "@ember/string", "I18n", "discourse-common/lib/deprecated"], function (_exports, _widget, _sidebars, _pluginApi, _string, _I18n, _deprecated) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.addSidebarProps = addSidebarProps;
  _exports.createLayoutsWidget = createLayoutsWidget;
  _exports.getAttrFromContext = getAttrFromContext;
  _exports.getContextFromAttr = getContextFromAttr;
  _exports.layoutsNamespace = void 0;
  _exports.listLayoutsWidgets = listLayoutsWidgets;
  _exports.listNormalisedContexts = listNormalisedContexts;
  _exports.lookupLayoutsWidget = lookupLayoutsWidget;
  _exports.normalizeContext = normalizeContext;
  _exports.setupContexts = setupContexts;
  const layoutsNamespace = _exports.layoutsNamespace = "layouts";
  const PLUGIN_ID = "discourse-layouts";
  const contexts = [{
    name: "discovery-categories",
    route: "discovery/categories",
    controller: "discovery/categories",
    template: "discovery/categories"
  }, {
    name: "discovery-latest",
    route: "discovery/latest",
    controller: "discovery/list",
    template: "discovery/list"
  }, {
    name: "discovery-unread",
    route: "discovery/unread",
    controller: "discovery/list",
    template: "discovery/list"
  }, {
    name: "discovery-top",
    route: "discovery/top",
    controller: "discovery/list",
    template: "discovery/list"
  }, {
    name: "discovery-category",
    route: "discovery/category",
    controller: "discovery/list",
    template: "discovery/list"
  }, {
    name: "latest-category",
    route: "discovery/latest-category",
    controller: "discovery/list",
    template: "discovery/list"
  }, {
    name: "top-category",
    route: "discovery/top-category",
    controller: "discovery/list",
    template: "discovery/list"
  }, "topic", "user", "users", "tags-index", {
    name: "tag-show",
    route: "tag-show",
    controller: "discovery/list",
    template: "discovery/list"
  }, "groups-index", "groups-new", "group", {
    name: "badges-index",
    route: "badges-index",
    controller: "badges/index"
  }, {
    name: "badges-show",
    route: "badges-show",
    controller: "badges/show"
  }, "review", "admin", {
    name: "search",
    route: "full-page-search",
    controller: "full-page-search",
    template: "full-page-search"
  }];
  function addSidebarProps(props) {
    let container = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    if ($.isEmptyObject(props)) {
      return;
    }
    if (!container) {
      (0, _deprecated.default)("you must pass 'container' as a second argument to addSidebarProps", {
        dropFrom: "3.0.0"
      });
    }

    // eslint-disable-next-line no-undef
    container = container || Discourse.__container__;
    const appEvents = container.lookup("service:app-events");
    contexts.forEach(context => {
      const controllerName = contextAttr(context, "controller");
      const controller = container.lookup(`controller:${controllerName}`);
      if (controller) {
        controller.set("customSidebarProps", Object.assign({}, controller.customSidebarProps, props));
      }
    });
    appEvents.trigger("sidebars:rerender");
  }
  const _layouts_widget_registry = {};
  const namespace = "layouts";
  function createLayoutsWidget(name, opts) {
    const fullName = `${namespace}-${name}`;
    const widget = (0, _widget.createWidget)(fullName, Object.assign({}, {
      tagName: `div.widget-container.${fullName}`,
      buildKey: () => fullName,
      // eslint-disable-next-line no-unused-vars
      shouldRender(attrs) {
        return true;
      }
    }, opts));
    _layouts_widget_registry[fullName] = widget;
    return widget;
  }
  function lookupLayoutsWidget(name) {
    return _layouts_widget_registry[name];
  }
  function listLayoutsWidgets() {
    return Object.keys(_layouts_widget_registry);
  }
  function normalizeContext(input) {
    let opts = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    let map = {
      "discovery-latest": ["discovery-latest"],
      "discovery-categories": ["discovery-categories"],
      "discovery-unread": ["discovery-unread"],
      "discovery-top": ["discovery-top"],
      "discovery-category": ["discovery-category"],
      "latest-category": ["latest-category"],
      "top-category": ["top-category"],
      topic: ["topic", "Topic"],
      user: ["user", "profile", "User", "Profile"],
      users: ["users"],
      tag: ["tag", "tags", "Tag", "Tags", "tags-index", "tag-show"],
      group: ["group", "groups-index", "groups-new"],
      badge: ["badge", "badges-index", "badges-show"],
      review: ["review"],
      admin: ["admin"],
      search: ["search"]
    };
    let context = Object.keys(map).find(c => map[c].includes(input));
    if (opts.name) {
      context = _I18n.default.t({
        "discovery-latest": "admin.layouts.widgets.context.latest",
        "discovery-categories": "admin.layouts.widgets.context.categories",
        "discovery-unread": "admin.layouts.widgets.context.unread",
        "discovery-top": "admin.layouts.widgets.context.top",
        "discovery-category": "admin.layouts.widgets.context.category",
        "latest-category": "admin.layouts.widgets.context.latest_category",
        "top-category": "admin.layouts.widgets.context.top_category",
        topic: "topic.title",
        user: "user.profile",
        users: "user.users",
        tag: "tagging.tags",
        group: "groups.title.one",
        badge: "admin.badges.badge",
        review: "review.title",
        admin: "admin_title",
        search: "search.search_button"
      }[context]);
    }
    return context;
  }
  function setupContexts() {
    contexts.forEach(context => {
      setupContext(context);
    });
  }
  function contextAttr(context, attr) {
    let result;
    if (typeof context === "object") {
      if (context[attr]) {
        result = context[attr];
      } else {
        result = context["name"];
      }
    } else {
      result = context;
    }
    if (attr === "template") {
      result = result.replace(/-/g, ".");
    }
    return result;
  }
  function getAttrFromContext(contextName, attr) {
    let result;
    contexts.some(context => {
      if (contextAttr(context, "name") === contextName) {
        result = contextAttr(context, attr);
        return true;
      }
    });
    return result;
  }
  function isCamelCase(value) {
    return /[a-z]+((\d)|([A-Z0-9][a-z0-9]+))*([A-Z])?/.test(value);
  }
  function getContextFromAttr(value, attr) {
    let result;
    if (attr === "route") {
      if (isCamelCase(value)) {
        value = (0, _string.dasherize)(value);
      } else if (value.indexOf(".") !== -1) {
        value = value.replace(/\./g, "-");
      }
    }
    contexts.some(context => {
      let contextValue = contextAttr(context, attr);
      if (contextValue === value) {
        result = contextValue;
        return true;
      }
    });
    return result;
  }
  function listNormalisedContexts() {
    return contexts.map(context => normalizeContext(contextAttr(context, "name")));
  }
  function setupContext(context) {
    const name = contextAttr(context, "name");
    const route = contextAttr(context, "route");
    const controller = contextAttr(context, "controller");
    const template = contextAttr(context, "template");
    const model = contextAttr(context, "model");
    (0, _pluginApi.withPluginApi)("0.8.32", api => {
      const currentUser = api.getCurrentUser();
      if (!((!currentUser || currentUser.admin === false) && route === "admin")) {
        api.modifyClass(`route:${route}`, {
          pluginId: PLUGIN_ID,
          renderTemplate() {
            this.render("sidebar-wrapper");
            this.render(template, {
              into: "sidebar-wrapper",
              outlet: "main-content",
              controller,
              model: this.modelFor(model)
            });
          }
        });
        let controllerClass = `controller:${controller}`;
        let controllerExists = api._resolveClass(controllerClass);
        if (controllerExists) {
          const klass = api._resolveClass(controllerClass, {});
          klass.class.reopen(_sidebars.default);
          api.modifyClass(controllerClass, {
            pluginId: PLUGIN_ID,
            layouts_context: name
          });
        } else {
          // eslint-disable-next-line no-console
          console.warn("Layouts context is missing a controller: ", name);
        }
      }
    });
  }
});