define("discourse/plugins/discourse-layouts/discourse/models/layout-widget", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object"], function (_exports, _ajax, _ajaxError, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const LayoutWidget = _object.default.extend();
  LayoutWidget.reopenClass({
    list() {
      return (0, _ajax.ajax)("/admin/layouts/widgets", {
        type: "GET"
      }).catch(_ajaxError.popupAjaxError);
    },
    save(widget) {
      return (0, _ajax.ajax)(`/admin/layouts/widgets/${widget.name}`, {
        type: widget.isNew ? "POST" : "PUT",
        data: {
          widget: JSON.parse(JSON.stringify(widget))
        }
      }).catch(_ajaxError.popupAjaxError);
    },
    remove(widget) {
      return (0, _ajax.ajax)(`/admin/layouts/widgets/${widget.name}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = LayoutWidget;
});