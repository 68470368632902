define("discourse/plugins/discourse-layouts/discourse/components/admin-widget-item", ["exports", "discourse/plugins/discourse-layouts/discourse/models/layout-widget", "discourse/plugins/discourse-layouts/discourse/lib/layouts", "discourse-common/utils/decorators", "@ember/object/computed", "@ember/object", "@ember/component", "I18n"], function (_exports, _layoutWidget, _layouts, _decorators, _computed, _object, _component, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  function buildSelectKit(items) {
    let type = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
    return items.map(item => {
      let name = item;
      let id = item;
      if (["position", "order"].indexOf(type) > -1 && isNaN(item)) {
        name = _I18n.default.t(`admin.layouts.widgets.${type}.${item}`);
      }
      if (type === "filter") {
        name = _I18n.default.t(`filters.${item}.title`);
      }
      if (type === "context") {
        name = (0, _layouts.normalizeContext)(item, {
          name: true
        });
      }
      if (["group", "category"].indexOf(type) > -1) {
        if (item === 0) {
          name = _I18n.default.t("categories.all");
        } else {
          name = item.name;
          id = item.id;
        }
      }
      return {
        id,
        name
      };
    });
  }
  function generateDisplayName(name) {
    return name.replace("layouts-", "").replace(/[_\-]+/g, " ").replace(/(^\w|\b\w)/g, m => m.toUpperCase());
  }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("widgets.length"), _dec2 = (0, _decorators.default)("site.groups"), _dec3 = (0, _decorators.default)("site.categories"), _dec4 = (0, _decorators.default)("widget.name"), (_obj = {
    classNames: "admin-layouts-widget",
    saveDisabled: (0, _computed.not)("dirty"),
    dirty: false,
    positionList: (0, _object.computed)(function () {
      return buildSelectKit(["left", "right"], "position");
    }),
    orderList(widgetCount) {
      const items = ["start", "end"];
      if (widgetCount > 0) {
        for (let i = 1; i <= widgetCount; i++) {
          items.push(i.toString());
        }
      }
      return buildSelectKit(items, "order");
    },
    contextList: (0, _object.computed)(function () {
      return buildSelectKit((0, _layouts.listNormalisedContexts)(), "context");
    }),
    filterList: (0, _object.computed)(function () {
      return buildSelectKit([...this.site.filters, "top", "categories"], "filter");
    }),
    groupList(siteGroups) {
      let list = buildSelectKit(siteGroups.filter(group => {
        return group.name !== "everyone";
      }), "group");
      return list;
    },
    categoryList(siteCategories) {
      return buildSelectKit([0, ...siteCategories], "category");
    },
    didInsertElement() {
      this._super(...arguments);
      if (!this.widget.isNew) {
        this.set("existingWidget", JSON.parse(JSON.stringify(this.widget)));
      }
    },
    update(type, value) {
      this.set(`widget.${type}`, value);
      const widget = this.widget;
      if (widget.isNew) {
        this.set("dirty", !!widget.name);
      } else {
        this.set("dirty", value !== this.existingWidget[type]);
      }
    },
    widgetDisplayName(name) {
      return generateDisplayName(name);
    },
    widgetList() {
      return (0, _layouts.listLayoutsWidgets)().map(name => {
        return {
          id: name,
          name: generateDisplayName(name)
        };
      });
    },
    actions: {
      updateOrder(order) {
        this.update("order", order);
      },
      updatePosition(position) {
        this.update("position", position);
      },
      updateGroups(groups) {
        this.update("groups", groups);
      },
      updateEnabled(enabled) {
        this.update("enabled", enabled);
      },
      updateCategoryIds(categoryIds) {
        this.update("category_ids", categoryIds);
      },
      updateExcludedCategoryIds(categoryIds) {
        this.update("excluded_category_ids", categoryIds);
      },
      updateFilters(filters) {
        this.update("filters", filters);
      },
      updateContexts(contexts) {
        this.update("contexts", contexts);
      },
      updateNewWidgetName(widget) {
        this.set("widget.name", widget);
      },
      save() {
        if (!this.dirty) {
          return false;
        }
        const widget = this.widget;
        if (widget.isNew && !widget.name) {
          return false;
        }
        this.set("saving", true);
        _layoutWidget.default.save(widget).then(result => {
          if (result.widget) {
            this.setProperties({
              widget: result.widget,
              existingWidget: JSON.parse(JSON.stringify(result.widget))
            });
          } else if (this.existingWidget) {
            this.set("widget", this.existingWidget);
          }
          this.set("dirty", false);
        }).finally(() => this.set("saving", false));
      },
      remove() {
        const widget = this.widget;
        if (!widget) {
          return false;
        }
        this.set("saving", true);
        _layoutWidget.default.remove(widget).then(result => {
          if (result.success) {
            this.removeWidget(widget);
          } else {
            this.set("saving", false);
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "orderList", [_dec], Object.getOwnPropertyDescriptor(_obj, "orderList"), _obj), _applyDecoratedDescriptor(_obj, "groupList", [_dec2], Object.getOwnPropertyDescriptor(_obj, "groupList"), _obj), _applyDecoratedDescriptor(_obj, "categoryList", [_dec3], Object.getOwnPropertyDescriptor(_obj, "categoryList"), _obj), _applyDecoratedDescriptor(_obj, "widgetDisplayName", [_dec4], Object.getOwnPropertyDescriptor(_obj, "widgetDisplayName"), _obj), _applyDecoratedDescriptor(_obj, "widgetList", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "widgetList"), _obj)), _obj)));
});